//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchInside from '../../components/mshop/HeaderSearchInside.vue'
import MealList from '../../components/mshop/ShopList.vue'
import { miceService } from '@/service/miceService'
import PullTo from 'vue-pull-to';
export default {
  name: 'search',
  components: {
    SearchInside,
    MealList,
    PullTo
  },
//  watch:{
//    'listData':'setHeight'
//  },
  activated: function () {
    if( this.$root.goStatus==0){
      this._data.listData = [];
    }else{
      this.keyWordChange();
    }
  },
  methods: {
    listenToSearch: function (data) {
      this.$route.query.keyword = this._data.searchParameter.keyword = data;
      this._data.keyword = data;
      this._data.searchParameter.pageIndex = 1;
      this._data.searchParameter.isSearch=true;
      this.loadShopList();
      this.keyWordChange();
    },
    listenToList: function (data) {
      sessionStorage.setItem('selectedShop', JSON.stringify(data))
      this.$router.push({name: 'Detail', query: {rowId: data.rowId}})
    },
    listenToListLoadMore: function () {
      this._data.searchParameter.isSearch=false;
      this.loadShopList();
    },
    loadShopList:function(){
      var _this = this;
      console.log(this.$route.query)
      _this._data.searchParameter.cityId = this.$route.query.cityid || 1;
      _this._data.searchParameter.proposaltype = this.$route.query.proposaltype;
      if(this.$route.query.hospitalfixed){
        _this._data.searchParameter.hospitalid =  this.$route.query.hospitalid
      }
      miceService.search(this._data.searchParameter).then(res => {
        if( _this._data.searchParameter.isSearch==true){
           _this._data.listData = [];
        }
        _this._data.searchParameter.pageIndex++;
        _this.common.arrPushArr(_this._data.listData, res.data)
      })
    },
    keyWordChange:function(){
       var keyword= this._data.searchParameter.keyword ? this._data.searchParameter.keyword: this.$route.query.keyword;
       this.$eventHub.$emit('keyWordChange',keyword);
    }
//    setHeight:function () {
//      var winHeight=this.$(window).height();
//      var headHeight=this.$('.listHead').height();
//      this.listHeight=winHeight-headHeight;
//      this.$('.listContent').height(this.listHeight);
//    }
  },
  data () {
    return {
      searchData: {
        placeholder: '请输入商户名'
      },
      searchParameter: {},
      listData: [],
      listHeight:0
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SearchInside",
  props: ["value"],
  activated: function() {
    this._data.keyword = this.common.toLowerCase(this.$route.query).keyword;
    this.getItem();
  },
  mounted: function() {
    var that = this;
    this.$eventHub.$on("keyWordChange", function(data) {
      that.keyword = data;
    });
  },
  methods: {
    closeSearch: function(e) {
      e.stopPropagation();
      this.$router.go(-1);
    },
    search: function(data, e) {
      e.stopPropagation();
      this.historyLook = false;
      if (!data) {
        alert(this.value.placeholder);
      } else {
        this.saveItem(data);
        this.$emit("fromSearchInside", data);
      }
    },
    saveItem: function(data) {
      this._data.searchArr.push(data);
      this._data.searchArr = this.common.unique(this._data.searchArr);
      if (this._data.searchArr.length > 6) {
        this._data.searchArr.shift();
      }
      localStorage.setItem("searchArr", this._data.searchArr);
    },
    clearItem: function() {
      this._data.searchArr = [];
      localStorage.setItem("searchArr", this._data.searchArr);
    },
    getItem: function() {
      this._data.searchArr = [];
      if (localStorage.getItem("searchArr")) {
        this._data.searchArr = localStorage.getItem("searchArr").split(",");
      }
    },
    changeHistory: function() {
      if (this.historyLook) {
        this.historyLook = false;
      } else {
        this.historyLook = true;
      }
    }
  },
  data: function() {
    return {
      keyword: null,
      historyLook: false,
      searchArr: []
    };
  }
};
